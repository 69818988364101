<template>
  <div>
    <div class="record-qr-code" id="record-qr-code">
      <div>
        <span class="app-title">
          Travaux-mn
        </span>
        <span class="view-title">
          <i class="mdi mdi-folder-open"/>
          {{panel.title}}
        </span>
        <i :class="`view-icon mdi mdi-${panel.icon || 'table'} mdi-48px`" />
      </div>
      <QrCode class="qr-code" :value="qrcodedata" />
      <div class="print-overlay" @click="print">
        <i class="mdi mdi-48px mdi-printer" /><br/>
        Imprimer
      </div>
    </div>
  </div>
</template>
<script>
import QrCode from '@/components/ui/QrCode';

export default {
  props: {
    panel: {
      type: Object,
      required: true
    }
  },
  components: {
    QrCode
  },
  computed: {
    qrcodedata () {
      return window.location.origin + '/#' + this.$router.currentRoute.fullPath;
    }
  },
  methods: {
    print () {

    }
  }
}
</script>
<style scoped>
.record-qr-code {
  margin: 20px;
  position: relative;
  display: inline-block;
  border: 4px solid black;
  border-radius: 8px;
  width: 236px;
  height: 286px;
  background: black;
  color: white;
}
.record-qr-code > div {
  padding-left: 2px;
}
.app-title {
  text-transform: uppercase;
  font-size:70%;
  font-weight: bold;
  position: absolute;
  color: #aaa;
  top: 0;
}
.view-title {
  position: absolute;
  top: 17px;
  width: 170px;
  font-size: 125%;
  text-overflow: ellipsis;
}
.view-icon {
  position: absolute;
  right: 0;
  top: -12px;
}
.qr-code {
  position: absolute;
  top: 50px;
  left: 0;
}
.print-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  line-height: 28px;
  background: #ccccccdd;
  color: black;
  cursor: pointer;
  padding-top: 120px;
  opacity: 0;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.record-qr-code:hover .print-overlay {
  opacity: 1;
}
</style>
